import Link from 'next/link';
import { ComponentPropsWithoutRef } from 'react';
import { focusRingClassNames } from '~/modules/ui/common-classnames';
import { cn } from '~/modules/ui/cva';

export function InlineInternalLink({
  className,
  ...props
}: ComponentPropsWithoutRef<typeof Link>) {
  return (
    <Link
      className={cn(
        focusRingClassNames,
        'focus-visible:t2-rounded-sm focus-visible:t2-ring-offset-background',
        't2-text-foreground t2-decoration-1 t2-underline-offset-2 hover:t2-underline',
        className,
      )}
      {...props}
    />
  );
}

export function InlineExternalLink({
  className,
  ...props
}: ComponentPropsWithoutRef<'a'>) {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      className={cn(
        focusRingClassNames,
        'focus-visible:t2-rounded-sm focus-visible:t2-ring-offset-background',
        't2-text-foreground t2-decoration-1 t2-underline-offset-2 hover:t2-underline',
        className,
      )}
      {...props}
    />
  );
}
