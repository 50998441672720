import type { FC } from 'react';
import { cn } from '~/modules/ui/cva';
import { InlineExternalLink } from '~/modules/ui/primitives/inline-link';
import { HStack } from '~/modules/ui/primitives/stack';

/**
 * Anything in here has to be approved by the Legal team at Lithic and Patriot
 * bank. So if you change anything just be aware it will result in a potential
 * copy review by them.
 */

interface ElectronicDisclaimerProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const ElectronicDisclaimer: FC<ElectronicDisclaimerProps> = (props) => {
  return (
    <HStack gap="2" className="items-center">
      <input
        type="checkbox"
        checked={props.checked ?? false}
        onChange={() => props.onChange(!props.checked)}
        className="rounded"
        data-test-id="electronic-communications-checkbox"
      />
      <p>
        I agree to the{' '}
        <InlineExternalLink href="/legal/e-sign">
          Electronic communications agreement
        </InlineExternalLink>
      </p>
    </HStack>
  );
};

interface BankingDisclaimerProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const BankingDisclaimer: FC<BankingDisclaimerProps> = (props) => {
  return (
    <HStack gap="2" className="items-center">
      <input
        type="checkbox"
        checked={props.checked ?? false}
        onChange={() => props.onChange(!props.checked)}
        className="rounded"
        data-test-id="direct-deposit-checkbox"
      />
      <p>
        I agree to the{' '}
        <InlineExternalLink href="/legal/business-banking">
          Business banking agreement
        </InlineExternalLink>
      </p>
    </HStack>
  );
};

const AccountDisclaimer = () => {
  return (
    <p
      className={cn('t2-text-body t2-text-foreground-secondary t2-text-pretty')}
    >
      By signing up you agree to Tola&apos;s{' '}
      <InlineExternalLink href="/legal/terms">
        Terms of service
      </InlineExternalLink>
      {', '}
      <InlineExternalLink href="/legal/privacy">
        Privacy policy
      </InlineExternalLink>
      {', and '}
      <InlineExternalLink href="/legal/originating">
        Origination agreement
      </InlineExternalLink>
    </p>
  );
};

export const NotBankDisclaimer = () => {
  return (
    <p
      className={cn('t2-text-body t2-text-foreground-secondary t2-text-pretty')}
    >
      Tola Inc. is not a FDIC-insured institution. Banking services provided by
      Patriot Bank, N.A., Member FDIC.
    </p>
  );
};

const SignupDisclaimer = () => {
  return (
    <p
      className={cn('t2-text-body t2-text-foreground-secondary t2-text-pretty')}
    >
      By signing up you agree to Tola&apos;s{' '}
      <InlineExternalLink href="/legal/terms">
        Terms of service
      </InlineExternalLink>
      {' and '}
      <InlineExternalLink href="/legal/privacy">
        Privacy policy
      </InlineExternalLink>
      .
    </p>
  );
};
