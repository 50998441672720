'use client';

import type { NextPage } from 'next';
import type { ReactElement, ReactNode } from 'react';
import { CRC } from '~/components/CRC';

export type NextPageWithLayout<TProps = Record<string, unknown>> =
  NextPage<TProps> & {
    getLayout?: (page: ReactElement) => ReactNode;
    /**
     * Require MFA to be set up before rendering the page
     * Is `true` of any `/org/*` page, and `false` for all other pages
     */
    requireMfaIfLoggedIn?: boolean;
  };

export const Single: CRC = (props) => {
  return (
    <div
      className="flex h-fill min-h-fill w-full flex-col text-gray-700"
      {...props}
    >
      {props.children}
    </div>
  );
};
